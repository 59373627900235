import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import firebase from 'firebase/app'
import 'firebase/firestore'
// import 'firebase/auth'
import VueCookies from 'vue-cookies'
// import { checkadmin } from '@/API/Security/CheckAdmin.js'
Vue.use(VueCookies)
Vue.use(VueCryptojs)

const config = {
  apiKey: 'AIzaSyBKsIPM09FE_QY1mrkdRVJ8KZYJm83rSF0',
  authDomain: 'premwit2021.firebaseapp.com',
  databaseURL: 'https://premwit2021-default-rtdb.firebaseio.com',
  projectId: 'premwit2021',
  storageBucket: 'premwit2021.appspot.com',
  messagingSenderId: '908869500001',
  appId: '1:908869500001:web:90c4966b81e529a06a3e20',
  measurementId: 'G-F05X21FTV1'
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

var db = firebase.firestore()
export default async function CheckCookie () {
  if (!Vue.$cookies.isKey('SID')) return false
  const SID = Vue.$cookies.get('SID')
  const EID = Vue.$cookies.get('EID')
  const GID = Vue.$cookies.get('GID')

  try {
    const user = await db
      .collection('userLogin')
      .doc(EID)
      .get()
    if (!user.exists) {
      Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
      alert('Please do not hack my cutie website (Fuckoff dam Hacker)')
      return false
    } else {
      const DBHash = user.data().hash
      try {
        const DBSid = Vue.CryptoJS.AES.decrypt(DBHash, GID).toString(Vue.CryptoJS.enc.Utf8)
        if (DBSid === SID) {
          return true
        } else {
          Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
          alert('Please do not hack my cutie website (Fuckoff dam Hacker)')
          return false
        }
      } catch (e) {
        Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
        console.error(e)
        return false
      }
    }
  } catch (e) {
    Vue.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
    return false
  }
}
