<template>
  <v-container>
    <v-card id="myinfo">
      <v-card-text>
        <p id="myinfo-title" class="display-1 text--primary">
          MY INFORMATION
        </p>
      </v-card-text>
      <v-form ref="mainref" v-model="mainmodel" lazy-validation>
        <!-- Personal_Info -->
        <v-divider class="mx-4"></v-divider>
        <v-container>
          <v-form
            ref="Personal_Info_ref"
            v-model="Personal_Info_form"
            lazy-validation
          >
            <v-card-actions>
              <p id="person-title">
                <v-icon>mdi-account</v-icon>
                PERSONAL INFORMATION
              </p>
              <v-spacer />
              <v-btn
                v-if="!edit"
                color="blue"
                class="ma-2 white--text"
                @click="edit = !edit"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
                EDIT
              </v-btn>
              <v-btn
                v-if="edit"
                color="green"
                class="ma-2 white--text"
                :disabled="!mainmodel"
                @click="save"
              >
                <v-icon>
                  mdi-content-save
                </v-icon>
                SAVE
              </v-btn>
            </v-card-actions>
            <v-row justify="center">
              <v-col cols="8" sm="4">
                <v-text-field
                  v-model="user.Name"
                  type="text"
                  :disabled="edit ? false : true"
                  :rules="[v => !!v || 'จำเป็นต้องมีชื่อ']"
                  label="ชื่อนักเรียน"
                  class="input-group--focused"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="8" sm="6">
                <v-text-field
                  v-model="user.Surname"
                  :disabled="edit ? false : true"
                  :rules="[v => !!v || 'จำเป็นต้องมีนามสกุล']"
                  type="text"
                  label="นามสกุลนักเรียน"
                  class="input-group--focused"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="8" sm="3">
                <v-text-field
                  v-model="user.Nickname"
                  :disabled="edit ? false : true"
                  :rules="[v => !!v || 'จำเป็นต้องมีชื่อเล่น']"
                  type="text"
                  label="ชื่อเล่น"
                  class="input-group--focused"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="8" sm="7">
                <v-text-field
                  v-model="user.PhoneNumber"
                  :disabled="edit ? false : true"
                  type="text"
                  label="Phone Number"
                  class="input-group--focused"
                ></v-text-field>
              </v-col>
              <v-col cols="8" sm="3">
                <v-text-field
                  v-model="user.Instagram"
                  :disabled="edit ? false : true"
                  :rules="[v => !!v || 'Instagram is required']"
                  label="Instagram"
                  class="input-group--focused"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="8" sm="4">
                <v-text-field
                  v-model="user.Facebook"
                  :disabled="edit ? false : true"
                  label="Facebook"
                  class="input-group--focused"
                ></v-text-field>
              </v-col>

              <v-col cols="8" sm="3">
                <v-text-field
                  v-model="user.LineID"
                  :disabled="edit ? false : true"
                  label="LineID"
                  class="input-group--focused"
                ></v-text-field>
              </v-col>

              <v-col cols="8" sm="4">
                <v-text-field
                  v-model="user.ParentName"
                  type="text"
                  :disabled="edit ? false : true"
                  :rules="[v => !!v || 'จำเป็นต้องมีชื่อผู้ปกครอง']"
                  label="ชื่อผู้ปกครอง"
                  class="input-group--focused"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="8" sm="6">
                <v-text-field
                  v-model="user.ParentSurname"
                  :disabled="edit ? false : true"
                  type="text"
                  :rules="[v => !!v || 'จำเป็นต้องมีนามสกุลผู้ปกครอง']"
                  label="นามสกุลผู้ปกครอง"
                  class="input-group--focused"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="8" sm="3">
                <v-text-field
                  v-model="user.Relation"
                  :disabled="edit ? false : true"
                  label="เกี่ยวข้องเป็น"
                  class="input-group--focused"
                ></v-text-field>
              </v-col>
              <v-col cols="8" sm="7">
                <v-text-field
                  v-model="user.ParentPhone"
                  :disabled="edit ? false : true"
                  label="เบอร์โทรศัพท์ผู้ปกครอง"
                  class="input-group--focused"
                ></v-text-field>
              </v-col>
              <v-col v-if="user.Photo=''" align-self="center" cols="11" md="6" lg="5">
                <v-card :disabled="edit ? false : true">
                  <v-card-title>Upload</v-card-title>
                  <v-card-subtitle style="margin-bottom: -16px;">
                    Upload Guardian Approval Form for PreMWIT2021
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-row>
                      <v-col>
                        <vue-dropzone
                          id="customdropzone"
                          ref="myVueDropzone"
                          :options="dropzoneOptions"
                          :use-custom-slot="true"
                          @vdropzone-file-added="imgupload"
                        >
                          <div class="dropzone-custom-content">
                            <v-icon :size="id_box_height">
                              mdi-card-account-details
                            </v-icon>
                            <h5 class="dropzone-custom-title">
                              Upload your document here
                            </h5>
                          </div>
                        </vue-dropzone>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-form></v-container
        >
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Updateinfo from '@/scripts/Updateinfo.js'
import getinfo from '@/scripts/Getinfo.js'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import getdata from '@/scripts/getdata.js'
import checklogin from '@/API/Security/CheckCookie.js'
// import VueCookies from 'vue-cookies'
export default {
  name: 'Home',
  components: {
    vueDropzone: vue2Dropzone
  },
  beforeRouteEnter (to, from, next) {
    if (checklogin()) { next() } else { next({ name: 'Home' }) }
  },
  data () {
    return {
      validall: true,
      TermAndCon: false,
      mainmodel: true,
      Title_item: ['Mr', 'Miss', 'Mrs', 'Ms', 'Mx'],
      Personal_Info_form: true,
      Contact_form: true,
      Education_form: true,
      Career_form: true,
      label: {},
      edit: false,
      photo: '',
      user: {
        Name: '',
        Surname: '',
        Nickname: '',
        PhoneNumber: '',
        Instagram: '',
        Facebook: '',
        LineID: '',
        ParentName: '',
        ParentSurname: '',
        Relation: '',
        ParentPhone: '',
        Photo: ''
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 400,
        addRemoveLinks: true,
        acceptedFiles: 'image/*',
        maxFiles: 1,
        init: function () {
          this.on('maxfilesexceeded', function (file) {
            this.removeAllFiles()
            this.addFile(file)
          })
        }
      },
      rules: {
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ],
        PhoneRules: [
          v => !!v || 'Phone Number is required',
          v => /\d{9,10}/.test(v) || 'Phone Number is not valid'
        ],
        NationalIDRules: [
          v => !!v || 'National ID is required',
          v => /\d{13}/.test(v) || 'National ID is not valid'
        ],
        StudentIDRules: [
          v => !!v || 'Student ID is required',
          v => /\d{5}/.test(v) || 'Student ID must be 5 Digits'
        ]
      }
    }
  },
  computed: {
    id_height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 50
        case 'sm':
          return 70
        case 'md':
          return 100
        case 'lg':
          return 120
        case 'xl':
          return 140
        default:
          return 40
      }
    },
    id_box_height () {
      if (this.files_data !== '') {
        return this.id_height_func() / 1.25
      } else return this.id_height_func()
    }
  },
  watch: {
    group () {}
  },
  mounted () {
    this.getfromdb()
    // this.fetchdata()
    if (this.user.Photo !== '') {
      // var file = { size: 123, name: 'Document', type: 'image/png' }
      var data = this.user.Photo
      this.$refs.myVueDropzone.manuallyAddFile(data)
    }
  },
  methods: {
    async submit () {
      await this.$refs.mainref.validate()
      if (this.form !== true) {
        this.validall = false
      }
    },
    // async fetchdata () {
    //   var udata = await getdata()
    // },
    async save () {
      await this.$refs.mainref.validate()
      if (this.mainmodel) {
        this.edit = !this.edit
        Updateinfo(this.user)
      }
    },
    getfromdb () {
      getinfo(this.user)
      // getlabel(this.label)
    },
    id_height_func () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 50
        case 'sm':
          return 70
        case 'md':
          return 100
        case 'lg':
          return 120
        case 'xl':
          return 140
        default:
          return 40
      }
    },
    imgupload (file) {
      var ResizeWidth = 500 // without px
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.name = file.name
      reader.size = file.size
      var img = new Image()
      // var resizedimg
      reader.onload = () => {
        img.src = event.target.result // result is base64-encoded Data URI
        img.name = event.target.name // set name (optional)
        img.size = event.target.size // set size (optional)
        img.onload = async () => {
          var elem = document.createElement('canvas') // create a canvas

          // scale the image to 600 (width) and keep aspect ratio
          var scaleFactor = ResizeWidth / event.target.width
          elem.width = ResizeWidth
          elem.height = event.target.height * scaleFactor

          // draw in canvas
          var ctx = elem.getContext('2d')
          ctx.drawImage(event.target, 0, 0, elem.width, elem.height)

          // get the base64-encoded Data URI from the resize image
          var srcEncoded = ctx.canvas.toDataURL(event.target, 'image/jpeg', 0)

          this.user.Photo = await srcEncoded
        }
      }
      // this.photo = resizedimg
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;500&display=swap');

#myinfo {
  width: 90%;
  left: 5%;
}

#myinfo-title {
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
}

#person-title {
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
}
</style>
