<template>
  <div class="SC">
    <v-col align='center'>
      <div style="margin-top: 35px; margin-bottom: 5px">
        <span class="hello text-h1">ตารางกิจกรรม</span>
      </div>
    </v-col>

    <v-container>
      <v-row>
        <v-col align="center">
          <div>
            <v-btn
              color="blue"
              large
              outlined
              rounded
              text
              elevation="6"
              @click="download()"
            >
              <v-icon>{{ mdiFileDownload }}</v-icon>
              ดาวโหลดไฟล์ ที่นี่
            </v-btn>
          </div>
        </v-col>

        <v-img src="../assets/Sh.webp" contain></v-img>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mdiFileDownload } from '@mdi/js'

export default {
  name: 'SC',
  data: () => ({
    mdiFileDownload
  }),
  methods: {
    download () {
      var element = document.createElement('a')
      element.setAttribute(
        'href',
        './assets/ตารางกิจ.pdf'
      )
      // element.setAttribute("download", "ตารางเวลา.pdf");

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)

      console.log('Downloaded')
    }
  }
}
</script>

<style>
.SC {
  width: 100%;
  position: absolute;
  /* top: 200px; */
  left: 0;
  background: url('/assets/BG/bg3.webp');
  background-repeat: repeat-y;
  background-color: white;
  transform: scale(1.0);
}
</style>
