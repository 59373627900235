import Vue from 'vue'
import VueCryptojs from 'vue-cryptojs'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import VueCookies from 'vue-cookies'
// import CheckCookie from '@/API/Security/CheckCookie.js'

Vue.use(VueCookies)
Vue.use(VueCryptojs)

const config = {
  apiKey: 'AIzaSyBKsIPM09FE_QY1mrkdRVJ8KZYJm83rSF0',
  authDomain: 'premwit2021.firebaseapp.com',
  databaseURL: 'https://premwit2021-default-rtdb.firebaseio.com',
  projectId: 'premwit2021',
  storageBucket: 'premwit2021.appspot.com',
  messagingSenderId: '908869500001',
  appId: '1:908869500001:web:90c4966b81e529a06a3e20',
  measurementId: 'G-F05X21FTV1'
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

var db = firebase.firestore()

export default async function main (data) {
  const encodedmail = Vue.$cookies.get('SID')
  const payload = data
  await db
    .collection('userData')
    .doc(encodedmail).set(payload)
  console.log('User data update successful')
}
